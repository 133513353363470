import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import ModalContext from "../../../SamplingRequest/context";
import SamplingRequest from "../../../SamplingRequest";
import {
  MODAL_CONTEXT_REQUEST,
  MODAL_CONTEXT_DOWNLOAD,
  MODAL_CONTEXT_CONTACT,
} from "./utils";
import "./styles/HeroLinks.scss";

function HeroLinks({ refProp, mobile }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [context, setContext] = useState(MODAL_CONTEXT_CONTACT);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div
        ref={refProp}
        className={`hero-links ${mobile ? "mobile" : "web"}`}
        style={{
          paddingRight: location.pathname !== "/" && "0",
        }}
      >
        <ul>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_REQUEST);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_REQUEST.title}</button>
          </li>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_DOWNLOAD);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_DOWNLOAD.title}</button>
          </li>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_CONTACT);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_CONTACT.title}</button>
          </li>
        </ul>
      </div>
      <ModalContext.Provider value={context}>
        <SamplingRequest isOpen={isOpen} toggle={toggle} />
      </ModalContext.Provider>
    </>
  );
}

HeroLinks.propTypes = {
  mobile: PropTypes.bool,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

export default HeroLinks;
