import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import DetailModal from "./components/DetailModal";
import heroBgEcosenseMain from "./img/hero_background.jpeg";

export default function Ecosense() {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <DetailModal isOpen={isOpen} toggle={toggle} />
      <div className="Ecosense">
        <div className="description">
          <h4 className="mt-3 text-center">
            <FormattedMessage
              id="Ecosense.higher"
              defaultMessage="Sustainability"
            />
          </h4>
          <div className="container-images cursor-pointer" onClick={toggle}>
            <img
              className="img-fluid"
              src={heroBgEcosenseMain}
              alt="ecosense"
            />
          </div>
          <div className="text-center">
            <button
              className="btn btn-dark rounded-pill my-4 px-3 text-uppercase"
              onClick={toggle}
            >
              <FormattedMessage
                id="Ecosense.readMore"
                defaultMessage="Read more"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
