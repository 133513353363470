import React from "react";
import Wrapper from "components/Wrapper";
import Paragraph from "components/Hero/components/Paragraph";

const Home = () => (
  <Wrapper col={12} subcol={6}>
    <div className="col-lg-12 col-xl-12">
      <Paragraph className="mt-5 outside-hero" />
    </div>
  </Wrapper>
);

export default Home;
