import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import arrowBack from "./img/back_arrow.svg";
import spradlingLogo from "./img/spradling_logo.svg";
import HeroLinks from "./components/HeroLinks";
import "./styles/toolbar.scss";

export default function Header() {
  const toolbar = useRef();
  const heroLinks = useRef();
  const location = useLocation();
  const [ismobile, setIsmobile] = useState(null);

  useEffect(() => {
    const x = window.matchMedia("(min-width: 755px)");
    responsive(x);
    handleStyles(x);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function responsive(x) {
    if (x.matches) {
      // If media query matches
      setIsmobile(false);
    } else {
      setIsmobile(true);
    }
  }

  function handleStyles(x) {
    x.addEventListener("change", responsive);
    window.onscroll = function (e) {
      if (x.matches && window.scrollY > 21) {
        toolbar.current.style.backgroundColor = "#fff";
        toolbar.current.style.boxShadow = "0 0 8px 0 rgba(0,0,0,0.5)";
      } else if (x.matches && window.scrollY < 21) {
        toolbar.current.style.backgroundColor = "transparent";
        toolbar.current.style.boxShadow = "0 0 0 0 rgba(0,0,0, 0.5)";
      } else if (!x.matches && window.scrollY > 21) {
        heroLinks.current.style.backgroundColor = "#fff";
        heroLinks.current.style.boxShadow = "0 8px 8px -4px rgba(0,0,0,0.5)";
      } else if (!x.matches && window.scrollY < 21) {
        heroLinks.current.style.backgroundColor = "rgba(255, 255, 255, 0.5)";
        heroLinks.current.style.boxShadow = "0 0 0 0 rgba(0,0,0, 0.5)";
      }
    };
  }

  const _backButton = () => {
    if (location.pathname !== "/" && ismobile) {
      return (
        <Link to="/">
          <img
            src={arrowBack}
            style={{ marginLeft: "0.5rem" }}
            width="20px"
            alt="..."
          />
        </Link>
      );
    }
  };

  const align = () => {
    if (location.pathname !== "/") {
      if (ismobile) {
        return "justify-content-between";
      }
    }
  };

  return (
    <header className="Toolbar p-0" ref={toolbar}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-7 col-xl-7 order-md-1 p-0">
            <HeroLinks refProp={heroLinks} mobile={ismobile} />
          </div>
          <div className="col-12 col-md-6 col-lg-5 col-xl-5 order-md-0">
            <div className={`logo d-flex align-items-center ${align()}`}>
              {_backButton()}
              <Link className="navbar-brand" to="/">
                <img src={spradlingLogo} alt="..." />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
