import React from "react";
import RouteManager from "routes";
import { LanguageProvider } from "context/languageContext";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "./styles/LandingLinetex.scss";

library.add(fas, fab);

function LandingLinetexApp() {
  return (
    <LanguageProvider>
      <RouteManager />
    </LanguageProvider>
  );
}

export default LandingLinetexApp;
