import Home from "modules/Home";
import Detail from "modules/Detail";
import NoMatch from "components/NoMatch";

export const NOT_FOUND_PAGE = "NOT_FOUND_PAGE";
export const HOME_PAGE = "HOME_PAGE";
export const DETAIL_PAGE = "DETAIL_PAGE";

export const SpradlingShowroomRoutes = {
  [HOME_PAGE]: {
    path: "/:lang",
    exact: true,
    component: Home,
  },
  [DETAIL_PAGE]: {
    path: "/:lang/detail/:slug",
    exact: true,
    component: Detail,
  },
  [NOT_FOUND_PAGE]: {
    path: "*",
    component: NoMatch,
  },
};
