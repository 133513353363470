import React, { useState } from "react";
import Select from "react-select";
import { Field } from "formik";
import colors from "assets/colors";

export default function CustomSelect() {
  const [isDisabled, setIsDisabled] = useState(false);

  const renderSelect = ({ field, form: { setFieldValue } }) => {
    const handleChange = (selected) => {
      let names = "";
      if (selected) {
        if (selected.length < 11) {
          setIsDisabled(false);
          names = selected.map((option) => option.label).join(",");
          if (selected.length === 10) {
            setIsDisabled(true);
          }
        }
      }
      setFieldValue(field.name, names);
    };
    return (
      <Select
        isMulti
        className="basic-multi-select"
        name="colors"
        isDisabled={isDisabled}
        options={colors}
        onChange={handleChange}
      />
    );
  };

  return <Field name="colours" render={renderSelect} />;
}
