import React from "react";
import { FormattedMessage } from "react-intl";

export const MODAL_CONTEXT_REQUEST = {
  id: 1,
  title: (
    <FormattedMessage
      id="ModalContext.requestTitle"
      defaultMessage="Request Samples"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.requestSubtitle"
      defaultMessage="request the samples of Linetex"
    />
  ),
  type: "SAMPLING REQUEST",
};

export const MODAL_CONTEXT_DOWNLOAD = {
  id: 2,
  title: (
    <FormattedMessage
      id="ModalContext.downloadTitle"
      defaultMessage="Download Digital Catalog"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitle"
      defaultMessage="download the digital catalog"
    />
  ),
  type: "DOWNLOAD DIGITAL CATALOGUE",
};

export const MODAL_CONTEXT_CONTACT = {
  id: 3,
  title: (
    <FormattedMessage id="ModalContext.contactTitle" defaultMessage="Contact" />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.contactSubtitle"
      defaultMessage="contact us"
    />
  ),
  type: "CONTACT",
};
