import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Vimeo from "@u-wave/react-vimeo";
import {
  DE_VIDEO_URL,
  EN_VIDEO_URL,
  ES_VIDEO_URL,
  FR_VIDEO_URL,
  IT_VIDEO_URL,
  PL_VIDEO_URL,
} from "./utils";
import { getCurrentLanguage } from "services";
import "./styles/ModalVideo.scss";

function ModalVideo({ isOpen, toggle }) {
  const lang = getCurrentLanguage();

  function getVideo() {
    let url;
    switch (lang) {
      case "de":
        url = DE_VIDEO_URL;
        break;
      case "en":
        url = EN_VIDEO_URL;
        break;
      case "es":
        url = ES_VIDEO_URL;
        break;
      case "fr":
        url = FR_VIDEO_URL;
        break;
      case "it":
        url = IT_VIDEO_URL;
        break;
      case "pl":
        url = PL_VIDEO_URL;
        break;
      default:
        // English one
        url = EN_VIDEO_URL;
        break;
    }
    return url;
  }

  return (
    <Modal
      className="modal-lg sampling-request-modal"
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader
        className="custom-modal-header border-0 pb-0"
        toggle={toggle}
      />
      <ModalBody>
        <div className="player-wrapper">
          <Vimeo video={getVideo()} autoplay responsive={true} />
        </div>
      </ModalBody>
    </Modal>
  );
}

ModalVideo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ModalVideo;
