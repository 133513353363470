import React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ColorsSlider from "./components/ColorsSlider";
import Paragraph from "./components/Paragraph";
import VideoPreview from "./components/VideoPreview";
import BackgroundImage, { getColorBySlug } from "./components/BackgroundImage";
import ScrollDown from "../ScrollDown";
import "./styles/Hero.scss";

export default function Hero() {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <BackgroundImage classes="hero" slug={match.params.slug}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-7 col-xl-7 order-md-1 p-0">
            <BackgroundImage classes="hero-bg" slug={match.params.slug}>
              {!match.params.slug && (
                <div className="hero-video-preview">
                  <VideoPreview />
                </div>
              )}
            </BackgroundImage>
          </div>
          <div className="col-12 col-md-6 col-lg-5 col-xl-5 pr-md-5 order-md-0">
            <div className="hero-content">
              <div>
                {!match.params.slug && (
                  <div className="hero-text">
                    <div className="hero-text__title">
                      <h3>
                        Linetex<sup className="sup">®</sup>
                      </h3>
                    </div>
                    <div className="hero-text__paragraph">
                      <Paragraph className="p-3 px-md-0" />
                    </div>
                  </div>
                )}

                {match.params.slug && (
                  <div className="hero-text py-md-5">
                    <div
                      className="hero-text__title"
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        paddingRight: "2rem",
                        color: "#fff",
                        top: "auto",
                        bottom: "130px",
                      }}
                    >
                      <h1>{getColorBySlug(match.params.slug).label}</h1>
                      <h6>{getColorBySlug(match.params.slug).sub}</h6>
                    </div>
                  </div>
                )}

                <div className="hero-slider">
                  <ColorsSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <a href="#info-section">
              <FontAwesomeIcon
                className="scroll"
                icon={faChevronDown}
                size="3x"
              />
            </a>
          </div>
        </div>
      </div>
      {location.pathname === "/" && <ScrollDown />}
    </BackgroundImage>
  );
}
