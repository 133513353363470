import React from "react";
import PropTypes from "prop-types";
import colors from "assets/colors";
import heroBg from "./img/bg.jpg";

export function getColorBySlug(slug) {
  return colors.find((color) => color.slug === slug);
}

function BackgroundImage({ classes, slug, children }) {
  return (
    <div
      className={classes}
      style={{
        backgroundImage: `url(${!slug ? heroBg : getColorBySlug(slug).image})`,
      }}
    >
      {children}
    </div>
  );
}

BackgroundImage.propTypes = {
  classes: PropTypes.string,
  slug: PropTypes.string,
  children: PropTypes.node,
};

export default BackgroundImage;
