import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { getCurrentLanguage } from "services";
import logoPermacool from "./img/logo_permacool.svg";
import outdoorMarina from "./img/outdoor_marina_permacool.jpg";
// Img Outdoor Hot Permacool
import outdoorHotDe from "./img/outdoorHotPermacool/de_outdoor_hot_permacool.png";
import outdoorHotEn from "./img/outdoorHotPermacool/en_outdoor_hot_permacool.jpg";
import outdoorHotEs from "./img/outdoorHotPermacool/es_outdoor_hot_permacool.png";
import outdoorHotFr from "./img/outdoorHotPermacool/fr_outdoor_hot_permacool.png";
import outdoorHotIt from "./img/outdoorHotPermacool/it_outdoor_hot_permacool.png";
import outdoorHotPl from "./img/outdoorHotPermacool/pl_outdoor_hot_permacool.png";
// Img Graphic
import graphicDe from "./img/graphic/de_graphic_permacool.png";
import graphicEn from "./img/graphic/en_graphic_permacool.jpeg";
import graphicEs from "./img/graphic/es_graphic_permacool.png";
import graphicFr from "./img/graphic/fr_graphic_permacool.png";
import graphicIt from "./img/graphic/it_graphic_permacool.png";
import graphicPl from "./img/graphic/pl_graphic_permacool.png";
import "./styles/detailModal.scss";

const DetailModal = ({ isOpen, toggle }) => {
  const lang = getCurrentLanguage();

  function getOutdoorHot() {
    let img;
    switch (lang) {
      case "de":
        img = outdoorHotDe;
        break;
      case "en":
        img = outdoorHotEn;
        break;
      case "es":
        img = outdoorHotEs;
        break;
      case "fr":
        img = outdoorHotFr;
        break;
      case "it":
        img = outdoorHotIt;
        break;
      case "pl":
        img = outdoorHotPl;
        break;
      default:
        // English one
        img = outdoorHotEn;
        break;
    }
    return img;
  }

  function getGraphic() {
    let img;
    switch (lang) {
      case "de":
        img = graphicDe;
        break;
      case "en":
        img = graphicEn;
        break;
      case "es":
        img = graphicEs;
        break;
      case "fr":
        img = graphicFr;
        break;
      case "it":
        img = graphicIt;
        break;
      case "pl":
        img = graphicPl;
        break;
      default:
        // English one
        img = graphicEn;
        break;
    }
    return img;
  }

  return (
    <Modal
      className="modal-lg sampling-request-modal"
      scrollable
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className="custom-modal-header border-0" toggle={toggle} />
      <ModalBody>
        <Container>
          <Row>
            <Col md={12}>
              <div className="mx-3 text-center">
                <img
                  className="w-50"
                  src={logoPermacool}
                  alt="logo permacool"
                />
                <p className="modal-title text-center">
                  <FormattedMessage
                    id="Permacool.Modal.title"
                    defaultMessage="Coated Fabrics cooler to the touch"
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3">
            <Col md={12}>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="Permacool.Modal.description"
                  defaultMessage="It’s common knowledge that heat from the sun can make dark surfaces feel much hotter than light ones.  And when its sunny outside, dark surfaces heat up even faster with no clouds to get in the way.​{br}{br}This is the reason why, sometimes, Outdoor, marine or automotive design and production avoid selecting dark colours as they reach excessive temperatures that don’t allow proper comfort for the final user.​"
                  values={{ br: <br /> }}
                />
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <img
                className="img-permacool"
                src={getOutdoorHot()}
                alt="hero permacool"
              />
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3">
            <Col md={12}>
              <h5 className="modal-subtitle text-how-works text-uppercase text-center">
                <FormattedMessage
                  id="Permacool.Modal.subtitle"
                  defaultMessage="The sun's rays have finally been tamed"
                />
              </h5>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="Permacool.Modal.descriptionV2"
                  defaultMessage="In response, Spradling® has developed an innovative technology to ensure more comfortable surfaces. ​A highly advanced reflective protection designed to reduce the amount of radiant energy or heat from being absorbed by those surfaces, improving the thermal sensation of your upholstery during sunny days.  You have to feel it to believe it!​{br}{br}​Designers and exterior seating producers are no longer limited to select lighter shades of colour. With Permacool Technology Spradling® offers an expanded colour palette, making even dark surfaces much cooler to the touch.​"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    br: <br />,
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <img
                className="img-permacool"
                src={outdoorMarina}
                alt="how it works"
              />
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3">
            <Col md={12}>
              <h5 className="modal-subtitle text-how-works text-uppercase text-center">
                <FormattedMessage
                  id="Permacool.Modal.HowItWorks.title"
                  defaultMessage="How it works?"
                />
              </h5>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="Permacool.Modal.HowItWorks.description"
                  defaultMessage="PermaCool technology reduces the surface temperature of your seating upholstery by up to 16°C when compared to regular vinyl-coated fabrics. Results were obtained following measurements done in accordance with ASTM D 4803-10**."
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3">
            <Col md={12}>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="Permacool.Modal.HowItWorks.descriptionV2"
                  defaultMessage="Temperature reduction capability may be reduced depending on print, embossing and colour of the coated fabric. Highest temperature reduction is achieved on darker upholstery shades."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <img
                className="w-100"
                src={getGraphic()}
                alt="graph temperature"
              />
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3">
            <Col md={12}>
              <h6 className="modal-text text-justify text-size-extra-small">
                <FormattedMessage
                  id="Permacool.Modal.HowItWorks.disclaimer"
                  defaultMessage="** Test conducted under controlled scientific conditions in a laboratory, following the ASTM D 4803-10 standard (prediction of the heat build-up in rigid and flexible PVC building products above ambient air temperature, relative to black, which occurs due to absorption of the sun’s energy). Temperature lectures were taken each 30 seconds during 20 minutes  with a plain black coated fabric test specimen (incorporating PERMACOOL technology) that is heated under an infrared reflective heat lamp to determine the temperature rise above ambient (laboratory) temperature relative to a black control sample"
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3">
            <Col md={12}>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="Permacool.Modal.HowItWorks.descriptionV2.1"
                  defaultMessage="In addition, due to the decreased heat absorption, there is less stress on your upholstery, making Coated fabrics with PermaCool a long-lasting alternative that provides superior longevity of your product."
                />
              </h6>
            </Col>
          </Row>
          <div className="text-center">
            <button
              className="btn btn-dark rounded-pill mt-5 px-5"
              onClick={toggle}
            >
              <FormattedMessage id="Permacool.close" defaultMessage="Close" />
            </button>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

DetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DetailModal;
