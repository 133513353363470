import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Divider } from "components/Custom";
import { items, other } from "./utils";
import "./detail.scss";

export default function CardDetail() {
  return (
    <div className="Detail mt-5">
      <Container>
        <Row>
          <Col>
            <h4 className="text-yale-blue">
              <FormattedMessage
                id="Detail.characteristics"
                defaultMessage="CHARACTERISTICS"
              />
            </h4>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[0][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[0][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[1][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[1][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[2][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[2][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[3][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[3][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[4][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[4][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[5][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">
              {">"}
              {items[5][1]}
            </h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[6][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[6][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{items[7][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[7][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{other[0][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Container className="px-0">
              {other[0][1].map((el) => (
                <Row>
                  <Col xs={1} sm={1} md={2}>
                    <h6 className="description">{el[0]}</h6>
                  </Col>
                  <Col xs={9} sm={9} md={10}>
                    <h6 className="description">{el[1]}</h6>
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
        <p className="italic-small text-size-small">
          *
          <FormattedMessage
            id="Detail.thisTerm"
            defaultMessage="This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."
          />
        </p>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-yale-blue">{other[1][0]}</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <div className="my-2 d-flex">
              <img className="images mr-4" src={other[1][1]} alt="permacool" />
              <img className="w-50" src={other[1][2]} alt="permablok3plus" />
            </div>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col
            className="d-flex align-items-center"
            xs={5}
            sm={5}
            md={5}
            lg={5}
            xl={5}
          >
            <h5 className="text-yale-blue">{other[2][0]}</h5>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{other[2][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <h5 className="text-yale-blue">{other[3][0]}</h5>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            {other[3][1].map((el, i) =>
              i === 0 ? (
                <img key={i} src={el} alt="ecosense" className="images" />
              ) : (
                <h6 key={i} className="description">
                  {" "}
                  {el}{" "}
                </h6>
              )
            )}
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <h5 className="text-yale-blue text-uppercase">{other[4][0]}</h5>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              {other[4][1].map(
                (element, iterator) => 
                <Row key={iterator} className="align-items-center justify-content-center my-3">
                  {
                    element.map((value, key) => (
                      key === 0 ? 
                        <Col key={`performance_${key}`} className="container-image-performance" xs={2} sm={2} md={2} lg={2} xl={2}>
                          <img
                            src={value}
                            alt={`performance_${key}`}
                          />
                        </Col>
                      : 
                        <Col 
                          key={`performance_${key}`} 
                          className="align-self-center"
                          xs={10} sm={10} md={10} lg={10} xl={10} 
                        >
                          <h6
                            className="description m-2"
                            style={{ fontSize: 10 }}
                          >
                            {value}
                          </h6>
                        </Col>
                    ))
                  }
                </Row>
              )}
            <p className="italic-small text-size-small">{other[4][2]}</p>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <h5 className="text-yale-blue">{other[5][0]}</h5>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{other[5][1]}</h6>
            <p className="small">{other[5][2]}</p>
          </Col>
        </Row>
        <Divider />
      </Container>
    </div>
  );
}
