import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./styles/paragraph.scss";

function Paragraph({ className }) {
  return (
    <div className={`paragraph ${className}`}>
      <h4 className="mb-3 text-uppercase">
        <FormattedMessage
          id="Paragraph.title"
          defaultMessage="A new fresh Coated Fabric which is cooler to the touch"
        />
      </h4>
      <p className="mb-lg-3 description description--light">
        <FormattedMessage
          id="Paragraph.subtitle"
          defaultMessage="Spradling<sup>®</sup> presents a timeless design, inspired by the natural beauty of linen fibres and its innate refreshing functionality, especially during the summer season.{br}Linetex<sup>®</sup> - a new Coated Fabric that transports us into the raw essence of design, combined with superb heat reflecting functionality.{br}This coated fabric offers a pleasing touch thanks to Permacool, our lastest industry leading technology, that helps to stop surfaces over heating even during the year's sunniest days!"
          values={{
            sup: (...chunks) => <sup>{chunks}</sup>,
            br: <br />,
          }}
        />
      </p>
    </div>
  );
}

Paragraph.propTypes = {
  className: PropTypes.string,
};

export default Paragraph;
