import React from "react";
import { FormattedMessage } from "react-intl";
import ecosense from "../img/ecosense.svg";
import permacool from "../img/permacool.svg";
import permablok3plus from "../img/permablok3_plus.svg";
import abrasionResistan from "../img/abrasion_resistan.jpg";
import impermeability from "../img/impermeability.jpg";
import uvResistant from "../img/uv_resistant.jpg";
import lowTempResistant from "../img/low_temp_resistant.jpg";
import mildewResistant from "../img/mildew_resistant.jpg";
import easyToClean from "../img/easy_to_clean.jpg";
import flameRetardant from "../img/flame_retardant.jpg";
import chemicalResistant from "../img/chemical_resistant.jpg";

export const items = [
  [
    <FormattedMessage
      id="Detail.itemComposition"
      defaultMessage="Composition"
    />,
    <FormattedMessage
      id="Detail.itemCoating"
      defaultMessage="Coating: Vinyl"
    />,
  ],
  [
    <FormattedMessage id="Detail.itemBacking" defaultMessage="Backing" />,
    <FormattedMessage
      id="Detail.item100Poly"
      defaultMessage="100% Polyester"
    />,
  ],
  [
    <FormattedMessage id="Detail.itemWeight" defaultMessage="Weight" />,
    <FormattedMessage id="Detail.item665" defaultMessage="665 gr/m2" />,
  ],
  [
    <FormattedMessage id="Detail.itemWidth" defaultMessage="Width" />,
    <FormattedMessage id="Detail.itemMin" defaultMessage="min. 137 cm" />,
  ],
  [
    <FormattedMessage id="Detail.itemRoll" defaultMessage="Roll size" />,
    <FormattedMessage id="Detail.itemMaomeno" defaultMessage="+/- 30m" />,
  ],
  [
    <FormattedMessage id="Detail.itemAbrasion" defaultMessage="Abrasion" />,
    <FormattedMessage
      id="Detail.item100"
      defaultMessage="100.000 cycles (MARTINDALE)"
    />,
  ],
  [
    <FormattedMessage id="Detail.itemCold" defaultMessage="Cold Crack" />,
    <FormattedMessage id="Detail.item29" defaultMessage="-29°C" />,
  ],
  [
    <FormattedMessage id="Detail.itemUV" defaultMessage="UV-Resistence" />,
    <span>
      <FormattedMessage
        id="Detail.b02"
        defaultMessage="ISO 105 B02: ≥ 7/8 (Blue Wool Scale)"
      />
      <br />
      <FormattedMessage
        id="Detail.b04"
        defaultMessage="ISO B04:105 ≥ 4/5 (Grey Scale)"
      />
    </span>,
  ],
];

export const other = [
  [
    <FormattedMessage
      id="Detail.otherTitle"
      defaultMessage="Flame retardancy*"
    />,
    [
      [
        "EU",
        <FormattedMessage id="Detail.EN" defaultMessage="EN 1021 Part 1 & 2" />,
      ],
      [
        "EU",
        <FormattedMessage
          id="Detail.MED"
          defaultMessage="(MED) Marine Equipment Directive"
        />,
      ],
      [
        "EU",
        <FormattedMessage
          id="Detail.IMO"
          defaultMessage="IMO 2010 FTP Part 8"
        />,
      ],
      [
        "DE",
        <FormattedMessage id="Detail.DIN4102B2" defaultMessage="DIN 4102 B2" />,
      ],
      [
        "US",
        <FormattedMessage id="Detail.FMVSS302" defaultMessage="FMVSS 302" />,
      ],
      [
        "US",
        <FormattedMessage
          id="Detail.CALTB"
          defaultMessage="CALTB 117-2013,Sec.E"
        />,
      ],
      [
        "US",
        <FormattedMessage
          id="Detail.UFAC"
          defaultMessage="UFAC Fabric - Class I"
        />,
      ],
      [
        "US",
        <FormattedMessage id="Detail.NFPA" defaultMessage="NFPA 260 Class I" />,
      ],
    ],
  ],
  [
    <FormattedMessage
      id="Detail.finishes"
      defaultMessage="FINISHES AND TREATMENTS"
    />,
    permacool,
    permablok3plus,
  ],
  [
    <FormattedMessage
      id="Detail.Antimicrobial"
      defaultMessage="Antimicrobial"
    />,
    <FormattedMessage
      id="Detail.Antimicrobial.detail"
      defaultMessage="Waterproof{br}Mildew Resistant Backing and Face{br}Antifungal Protection{br}Antibacterial Protection{br}Antimycotic Protection"
      values={{
        br: <br />,
      }}
    />,
  ],
  [
    <FormattedMessage
      id="Detail.sustainability"
      defaultMessage="SUSTAINABILITY"
    />,
    [
      ecosense,
      <FormattedMessage
        id="Detail.ISO14001"
        defaultMessage="ISO 14001 Environmental Managment"
      />,
      <FormattedMessage id="Detail.Carbon" defaultMessage="Carbon Neutral" />,
      <FormattedMessage id="Detail.REACH" defaultMessage="REACH" />,
      <FormattedMessage id="Detail.RoHS" defaultMessage="RoHS" />,
    ],
  ],
  [
    <FormattedMessage id="Detail.Performance" defaultMessage="Performance" />,
    [
      [
        abrasionResistan,
        <FormattedMessage
          id="Detail.Performance.AbrasionResistan"
          defaultMessage="Exzellente Abriebfestigkeit / Resistencia a la abrasión / Resistance a l’abrasion / Abrasion resistant / Resistenza all’abrasione / Doskonała odporność na ścieranie"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        impermeability,
        <FormattedMessage
          id="Detail.Performance.Impermeability"
          defaultMessage="Wasserabweisend / Impermeabilidad /Imperméabilité / Impermeability / Impermeabilità / Wodoodporny"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        uvResistant,
        <FormattedMessage
          id="Detail.Performance.UVResistant"
          defaultMessage="Lichtechtheit / Durabilidad del color a la luz / Résistance à la lumière / UV resistant / Resistenza raggi solari / Odporny na promieniowanie UV"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        lowTempResistant,
        <FormattedMessage
          id="Detail.Performance.LowTempResistant"
          defaultMessage="Kältebruchresistent / Resistencia a bajas temperaturas / Résistance aux basses températures / Low temp. resistant /Resistenza alla rottura da freddo / Odporny na niskie temperatury"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        mildewResistant,
        <FormattedMessage
          id="Detail.Performance.MildewResistant"
          defaultMessage="Antimikrobiell ausgerüstet / Resistencia a microorganismos / Protection contre des micro-organismes / Mildew resistant / Antimuffa / Odporny na pleśń"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        easyToClean,
        <FormattedMessage
          id="Detail.Performance.EasyToClean"
          defaultMessage="Einfache Reinigung / Fácil limpieza / Facilité d’entretien / Easy to clean / Facile da pulire / Łatwy do czyszczenia"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        flameRetardant,
        <FormattedMessage
          id="Detail.Performance.FlameRetardant"
          defaultMessage="Flammhemmend ausgerüstet / Retardancia al fuego / Retardement au feu / Flame retardant / Ritardanza al fuoco / Produkt uniepalniony"
          values={{
            br: <br />,
          }}
        />,
      ],
      [
        chemicalResistant,
        <FormattedMessage
          id="Detail.Performance.ChemicalResistant"
          defaultMessage="Chemikalienbeständig* / Resistencia a químicos* / Résistance aux produits chimiques* / Chemical resistant* / Resistenza chimica* / Odporny na chemikalia*"
          values={{
            br: <br />,
          }}
        />,
      ],
    ],
    <FormattedMessage
      id="Detail.Performance.footer"
      defaultMessage="Chemikalienbeständig* / Resistencia a químicos* / Résistance aux produits chimiques* / Chemical resistant* / Resistenza chimica* / Odporny na chemikalia*"
      values={{
        br: <br />,
      }}
    />,
  ],
  [
    <FormattedMessage id="Detail.warranty" defaultMessage="WARRANTY" />,
    <FormattedMessage id="Detail.5Years" defaultMessage="5 YEARS" />,
    <FormattedMessage
      id="Detail.Warranty.detail"
      defaultMessage="Terms and conditions apply.{br}Warranty conditions are displayed on our website"
      values={{
        br: <br />,
      }}
    />,
  ],
];
