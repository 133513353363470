export default [
  {
    label: "Bamboo",
    value: "bamboo",
    slug: "bamboo",
    sub: "LNT-3460",
    image: require("./images/collection/bamboo.jpg"),
  },
  {
    label: "Butter",
    value: "butter",
    slug: "butter",
    sub: "LNT-5194",
    image: require("./images/collection/butter.jpg"),
  },
  {
    label: "Cacao",
    value: "cacao",
    slug: "cacao",
    sub: "LNT-7686",
    image: require("./images/collection/cacao.jpg"),
  },
  {
    label: "Chalk",
    value: "chalk",
    slug: "chalk",
    sub: "LNT-9502",
    image: require("./images/collection/chalk.jpg"),
  },
  {
    label: "Chestnut",
    value: "chestnut",
    slug: "chestnut",
    sub: "LNT-8288",
    image: require("./images/collection/chestnut.jpg"),
  },
  {
    label: "Cliff",
    value: "cliff",
    slug: "cliff",
    sub: "LNT-1340",
    image: require("./images/collection/cliff.jpg"),
  },
  {
    label: "Dust",
    value: "dust",
    slug: "dust",
    sub: "LNT-2888",
    image: require("./images/collection/dust.jpg"),
  },
  {
    label: "Glacier",
    value: "glacier",
    slug: "glacier",
    sub: "LNT-4768",
    image: require("./images/collection/glacier.jpg"),
  },
  {
    label: "Iron",
    value: "iron",
    slug: "iron",
    sub: "LNT-8577",
    image: require("./images/collection/iron.jpg"),
  },
  {
    label: "Rock",
    value: "rock",
    slug: "rock",
    sub: "LNT-7893",
    image: require("./images/collection/rock.jpg"),
  },
  {
    label: "Shale",
    value: "shale",
    slug: "shale",
    sub: "LNT-4210",
    image: require("./images/collection/shale.jpg"),
  },
  {
    label: "Shallow",
    value: "shallow",
    slug: "shallow",
    sub: "LNT-8215",
    image: require("./images/collection/shallow.jpg"),
  },
  {
    label: "Sphere",
    value: "sphere",
    slug: "sphere",
    sub: "LNT-6480",
    image: require("./images/collection/sphere.jpg"),
  },
  {
    label: "Toast",
    value: "toast",
    slug: "toast",
    sub: "LNT-6027",
    image: require("./images/collection/toast.jpg"),
  },
  {
    label: "Truffle",
    value: "truffle",
    slug: "truffle",
    sub: "LNT-1323",
    image: require("./images/collection/truffle.jpg"),
  },
  {
    label: "Wave",
    value: "wave",
    slug: "wave",
    sub: "LNT-4571",
    image: require("./images/collection/wave.jpg"),
  },
];
