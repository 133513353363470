import React, { useState } from "react";
import ModalVideo from "components/ModalVideo";
import { getCurrentLanguage } from "services";
import previewDe from "./img/de_preview.jpg";
import previewEn from "./img/en_preview.jpg";
import previewEs from "./img/es_preview.jpg";
import previewFr from "./img/fr_preview.jpg";
import previewIt from "./img/it_preview.jpg";
import previewPl from "./img/pl_preview.jpg";
import "./styles/videoPreview.scss";

export default function VideoPreview() {
  const [isOpen, setIsOpen] = useState(false);
  const lang = getCurrentLanguage();

  function getPreview() {
    let img;
    switch (lang) {
      case "de":
        img = previewDe;
        break;
      case "en":
        img = previewEn;
        break;
      case "es":
        img = previewEs;
        break;
      case "fr":
        img = previewFr;
        break;
      case "it":
        img = previewIt;
        break;
      case "pl":
        img = previewPl;
        break;
      default:
        // English one
        img = previewEn;
        break;
    }
    return img;
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div
        className="video-preview"
        onClick={toggle}
        style={{
          backgroundImage: `url(${getPreview()})`,
        }}
      />
      <ModalVideo isOpen={isOpen} toggle={toggle} />
    </>
  );
}
