import React from "react";
import Wrapper from "components/Wrapper";
import CardDetail from "components/CardDetail";

const Detail = () => (
  <Wrapper col={6} subcol={12}>
    <div className="col-lg-6 col-xl-6">
      <CardDetail />
    </div>
  </Wrapper>
);

export default Detail;
