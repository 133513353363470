import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "assets/fonts/dalamoa/stylesheet.css";
import "assets/fonts/gramatika/stylesheet.css";
import "assets/fonts/eternate/stylesheet.css";
import "assets/fonts/gill-sans/stylesheet.css";
import "assets/styles/LandingLinetexApp.scss";
import LandingLinetexApp from "app";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import client from "./api";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <LandingLinetexApp />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
