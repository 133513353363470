import React, { useContext, useState } from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  MODAL_CONTEXT_CONTACT,
  MODAL_CONTEXT_DOWNLOAD,
  MODAL_CONTEXT_REQUEST,
} from "components/Header/components/HeroLinks/utils";
import CustomSelect from "components/SamplingRequest/components/CustomSelect";
import ModalContext from "components/SamplingRequest/context";
import FormErrorMessage from "utils/components/FormErrorMessage";
import validationMessages from "utils/forms/messages";
import countries from "assets/country.json";
import emailBody from "services/email/body";
import { emailsCountries, emailMessages } from "./constants/emails";
import { REQUEST_ENDPOINT, CATALOG_URL } from "./constants";
import tick from "./img/tick.svg";
import { useMutation } from "@apollo/client";
import createStoreLead from "./mutation";

export default function RequestForm() {
  const context = useContext(ModalContext);
  const intl = useIntl();
  const [createLead] = useMutation(createStoreLead);
  const [success, setSuccess] = useState(false);

  const initialValues = {
    request: "",
    colours: "",
    type: context.type,
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    address: "",
    postalCode: "",
    city: "",
    email: "",
    phone: "",
    comment: "",
    privacyPolicy: false,
  };

  const validationSchema = Yup.object({
    request:
      context === MODAL_CONTEXT_REQUEST
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    colours:
      context === MODAL_CONTEXT_REQUEST
        ? Yup.string().when("request", {
            is: (value) => value && value === "DinA4 Samples",
            then: Yup.string().required("Please select at least 1 color"),
            otherwise: Yup.string(),
          })
        : Yup.string(),
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyType: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    email: Yup.string()
      .email()
      .required(intl.formatMessage(validationMessages.required)),
    phone: Yup.number().required(
      intl.formatMessage(validationMessages.required)
    ),
    country: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    address:
      context === MODAL_CONTEXT_REQUEST
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    postalCode:
      context === MODAL_CONTEXT_REQUEST
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    city:
      context === MODAL_CONTEXT_REQUEST
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    comment:
      context === MODAL_CONTEXT_CONTACT
        ? Yup.string().required()
        : Yup.string(),
    privacyPolicy: Yup.bool().oneOf(
      [true],
      intl.formatMessage(validationMessages.required)
    ),
  });

  function getEmailBody(values) {
    const messages = [
      "thankYou",
      "intro",
      "summaryTitle",
      "product",
      "customerDetails",
      "shippingInformation",
      "swatchbook",
      "colour",
    ].map((name) => intl.formatMessage(emailMessages[name]));

    return emailBody(messages, values);
  }

  function getClientEmailInfo(values) {
    return {
      client_to: values.email,
      client_subject: intl.formatMessage(emailMessages.subject),
    };
  }

  function getAdminEmailInfo(values) {
    return {
      admin_to:
        emailsCountries[values.country] !== undefined
          ? emailsCountries[values.country]
          : "info.en@spradling.group",
      admin_subject: "You have a new Sample Request | Landing Linetex",
    };
  }

  const handlePostSubmit = ({ setSubmitting, resetForm }) => {
    setSubmitting(false);
    resetForm();
  };

  const handleZap = async (values) => {
    const emailBody =
      context === MODAL_CONTEXT_REQUEST ? getEmailBody(values) : null;
    const clientEmailInfo =
      context === MODAL_CONTEXT_REQUEST ? getClientEmailInfo(values) : null;
    const adminEmailInfo =
      context === MODAL_CONTEXT_REQUEST ? getAdminEmailInfo(values) : null;

    const data = JSON.stringify({
      ...values,
      ...clientEmailInfo,
      ...adminEmailInfo,
      emailBody,
      date: new Date().toLocaleString(),
    });

    try {
      await axios.post(REQUEST_ENDPOINT, data);
    } catch (e) {
      console.log("handleZap ERROR -> ", e);
    }
  };

  const onSubmit = async (
    { privacyPolicy, colours, comment, ...values },
    { setSubmitting, resetForm }
  ) => {
    const input = {
      source: "Linetex EU",
      colors: colours,
      comments: comment,
      ...values,
    };
    const inputZap = {
      colours,
      comment,
      ...values,
    };

    try {
      await createLead({ variables: { input } });
      await handleZap(inputZap);
      handlePostSubmit({ setSubmitting, resetForm });
      setSuccess(true);
    } catch (e) {
      console.log("ERROR crete contact on CONTACTFORM: ", e);
      handlePostSubmit({ setSubmitting, resetForm });
    }
  };

  return success ? (
    <>
      <div className="text-center">
        <img src={tick} alt="" className="pt-3 pb-4" height={110} />
      </div>
      <div className="text-center">
        {context === MODAL_CONTEXT_REQUEST && (
          <h4>
            <FormattedMessage
              id="SamplingRequest.successRequest"
              defaultMessage="We received your request and will be processing it soon!"
            />
          </h4>
        )}
        {context === MODAL_CONTEXT_CONTACT && (
          <h4>
            <FormattedMessage
              id="SamplingRequest.successContact"
              defaultMessage="Thanks for contacting us, we'll be in touch soon"
            />
          </h4>
        )}
        {context === MODAL_CONTEXT_DOWNLOAD && (
          <h4>
            <FormattedMessage
              id="SamplingRequest.successDownload"
              defaultMessage="Thanks for your interest in Linetex<sup>®</sup>! Please <a>click here</a> to download the PDF file"
              values={{
                sup: (...chunks) => <sup>{chunks}</sup>,
                a: (...chunks) => (
                  <a
                    href={CATALOG_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </h4>
        )}
      </div>
    </>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form noValidate>
          <Row>
            <Col md={12}>
              <p className="text-center px-5 mb-4">{context.subtitle}</p>
            </Col>
          </Row>
          {context === MODAL_CONTEXT_REQUEST && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="request">
                      <FormattedMessage
                        id="SamplingRequest.labelRequest"
                        defaultMessage="Request"
                      />{" "}
                      *
                    </label>
                    <Field
                      as="select"
                      id="request"
                      name="request"
                      className="form-control rounded-0"
                    >
                      <FormattedMessage
                        id="SamplingRequest.selectTypeRequest"
                        defaultMessage="Select your type of request"
                      >
                        {(message) => <option selected>{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage
                        id="SamplingRequest.optionDinA4"
                        defaultMessage="DinA4 Samples"
                      >
                        {(message) => (
                          <option value="DinA4 Samples">{message}</option>
                        )}
                      </FormattedMessage>
                      <FormattedMessage
                        id="SamplingRequest.optionSwatchbook"
                        defaultMessage="Swatchbook"
                      >
                        {(message) => (
                          <option value="Swatchbook">{message}</option>
                        )}
                      </FormattedMessage>
                      <FormattedMessage
                        id="SamplingRequest.optionColour"
                        defaultMessage="Colour Card"
                      >
                        {(message) => (
                          <option value="Colour Card">{message}</option>
                        )}
                      </FormattedMessage>
                    </Field>
                    <FormErrorMessage name="request" />
                  </div>
                </Col>
                {values.request === "DinA4 Samples" && (
                  <Col md={12}>
                    <div className="form-group">
                      <label htmlFor="colours">
                        <FormattedMessage
                          id="SamplingRequest.labelColour"
                          defaultMessage="Colour"
                        />{" "}
                        *
                      </label>
                      <CustomSelect />
                      <FormErrorMessage name="colours" />
                    </div>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          )}

          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="firstName">
                  <FormattedMessage
                    id="SamplingRequest.labelName"
                    defaultMessage="Name"
                  />{" "}
                  *
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="form-control rounded-0"
                />
                <FormErrorMessage name="firstName" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="lastName">
                  <FormattedMessage
                    id="SamplingRequest.labelSurname"
                    defaultMessage="Surname"
                  />{" "}
                  *
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="form-control rounded-0"
                />
                <FormErrorMessage name="lastName" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="companyName">
                  <FormattedMessage
                    id="SamplingRequest.labelCompany"
                    defaultMessage="Company"
                  />{" "}
                  *
                </label>
                <Field
                  id="companyName"
                  name="companyName"
                  type="text"
                  className="form-control rounded-0"
                />
                <FormErrorMessage name="companyName" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="companyType">
                  <FormattedMessage
                    id="SamplingRequest.labelCompanyType"
                    defaultMessage="Company Type"
                  />{" "}
                  *
                </label>
                <Field
                  as="select"
                  id="companyType"
                  name="companyType"
                  className="form-control rounded-0"
                >
                  <FormattedMessage
                    id="SamplingRequest.selectCompanyType"
                    defaultMessage="Select a company type"
                  >
                    {(message) => <option selected>{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionManufacturer"
                    defaultMessage="Manufacturer"
                  >
                    {(message) => (
                      <option value="manufacturer">{message}</option>
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionDistributor"
                    defaultMessage="Distributor"
                  >
                    {(message) => (
                      <option value="distributor">{message}</option>
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionArchitect"
                    defaultMessage="Architect"
                  >
                    {(message) => <option value="architect">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionDesigner"
                    defaultMessage="Designer"
                  >
                    {(message) => <option value="designer">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionOther"
                    defaultMessage="Other"
                  >
                    {(message) => <option value="other">{message}</option>}
                  </FormattedMessage>
                </Field>
                <FormErrorMessage name="companyType" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="country">
                  <FormattedMessage
                    id="SamplingRequest.labelCountry"
                    defaultMessage="Country"
                  />{" "}
                  *
                </label>
                <Field
                  as="select"
                  id="country"
                  name="country"
                  className="form-control rounded-0"
                >
                  <FormattedMessage
                    id="SamplingRequest.selectCountry"
                    defaultMessage="Select a country"
                  >
                    {(message) => <option value="">{message}</option>}
                  </FormattedMessage>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <FormErrorMessage name="country" />
              </div>
            </Col>

            {context === MODAL_CONTEXT_REQUEST && (
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="address">
                    <FormattedMessage
                      id="SamplingRequest.labelAddress"
                      defaultMessage="Address"
                    />{" "}
                    *
                  </label>
                  <Field
                    id="address"
                    name="address"
                    type="text"
                    className="form-control rounded-0"
                  />
                  <FormErrorMessage name="address" />
                </div>
              </Col>
            )}

            {context === MODAL_CONTEXT_REQUEST && (
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="postalCode">
                    <FormattedMessage
                      id="SamplingRequest.labelPostal"
                      defaultMessage="Postal Code"
                    />{" "}
                    *
                  </label>
                  <Field
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    className="form-control rounded-0"
                  />
                  <FormErrorMessage name="postalCode" />
                </div>
              </Col>
            )}

            {context === MODAL_CONTEXT_REQUEST && (
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="city">
                    <FormattedMessage
                      id="SamplingRequest.labelCity"
                      defaultMessage="City"
                    />{" "}
                    *
                  </label>
                  <Field
                    id="city"
                    name="city"
                    type="text"
                    className="form-control rounded-0"
                  />
                  <FormErrorMessage name="city" />
                </div>
              </Col>
            )}

            <Col md={6}>
              <div className="form-group">
                <label htmlFor="email">
                  <FormattedMessage
                    id="SamplingRequest.labelEmail"
                    defaultMessage="Email"
                  />{" "}
                  *
                </label>
                <Field
                  id="email"
                  name="email"
                  type="text"
                  className="form-control rounded-0"
                />
                <FormErrorMessage name="email" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="phone">
                  <FormattedMessage
                    id="SamplingRequest.labelPhone"
                    defaultMessage="Phone"
                  />{" "}
                  *
                </label>
                <Field
                  id="phone"
                  name="phone"
                  type="text"
                  className="form-control rounded-0"
                />
                <FormErrorMessage name="phone" />
              </div>
            </Col>
            {context === MODAL_CONTEXT_CONTACT && (
              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="comment">
                    <FormattedMessage
                      id="SamplingRequest.labelComment"
                      defaultMessage="Comment"
                    />{" "}
                    *
                  </label>
                  <Field
                    id="comment"
                    name="comment"
                    as="textarea"
                    className="form-control rounded-0"
                  />
                  <FormErrorMessage name="comment" />
                </div>
              </Col>
            )}
          </Row>

          <div className="form-group form-check text-center">
            <Field
              type="checkbox"
              name="privacyPolicy"
              id="privacyPolicy"
              className="form-check-input"
            />
            <label htmlFor="privacyPolicy" className="form-check-label">
              <FormattedMessage
                id="SamplingRequest.privacyPolicy"
                defaultMessage="I've read and agree the privacy policy"
              />
            </label>
            <FormErrorMessage name="privacyPolicy" />
          </div>
          <Row className="my-3">
            <div className="col-md-12 text-center">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-dark rounded-pill px-5"
              >
                {isSubmitting ? (
                  <FormattedMessage
                    id="SamplingRequest.btnLabelLoading"
                    defaultMessage="Sending..."
                  />
                ) : (
                  <FormattedMessage
                    id="SamplingRequest.btnLabel"
                    defaultMessage="Send"
                  />
                )}
              </button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
