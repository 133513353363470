import React from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Hero from "components/Hero";
import Permacool from "components/Permacool";
import Ecosense from "components/Ecosense";
import Footer from "components/Footer";

function Wrapper({ col, subcol, children }) {
  return (
    <div className="App">
      <Header />
      <Hero />
      <div id="info-section" className="container">
        <div className="row">
          {children}
          <div className={`col-lg-${col} col-xl-${col}`}>
            <div className="row">
              <div className={`col-lg-${subcol} col-xl-${subcol}`}>
                <Permacool />
              </div>
              <div className={`col-lg-${subcol} col-xl-${subcol}`}>
                <Ecosense />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

Wrapper.propTypes = {
  col: PropTypes.number.isRequired,
  subcol: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default Wrapper;
