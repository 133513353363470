import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormErrorMessage from "utils/components/FormErrorMessage";
import validationMessages from "utils/forms/messages";
import "./styles/NewsletterForm.scss";

export default function NewsletterForm() {
  const intl = useIntl();
  const [subscribed, setSubscribed] = useState(false);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.required))
      .email(intl.formatMessage(validationMessages.email)),
  });

  function handleSubmit(values, { setSubmitting, setStatus, resetForm }) {
    setSubmitting(true);
    values = { ...values, updateEnabled: false, listIds: [192] }; // WARNING: 192 is the identifier of list in sendinblue
    const promise = axios.post(
      "https://api.sendinblue.com/v3/contacts",
      values,
      {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "api-key":
            "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",
        },
      }
    );
    promise.then((response) => {
      setSubmitting(false);
      resetForm();
      setSubscribed(true);
    });
    promise.catch((error) => {
      if (error.response.data.code === "duplicate_parameter") {
        promise.finally();
      }
      setStatus({ ...error.response.data });
      setSubmitting(false);
    });
  }

  if (subscribed)
    return (
      <div className="d-flex h-100 align-items-center">
        <p>
          <FormattedMessage
            id="NewsletterForm.thankYou"
            description="Thank you message in NewsletterForm component"
            defaultMessage="¡Tu suscripción fue exitosa!"
          />
        </p>
      </div>
    );

  return (
    <div className="form-group">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting }) => (
          <Form noValidate>
            <h6>
              <FormattedMessage
                id="NewsletterForm.title"
                defaultMessage="Subscribe to Newsletter"
              />
            </h6>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <FormattedMessage
                    id="NewsletterForm.placeholderEmail"
                    defaultMessage="Email"
                  >
                    {(text) => (
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        placeholder={text}
                        className="input"
                      />
                    )}
                  </FormattedMessage>
                  <FormErrorMessage name="email" />
                </div>
              </Col>
            </Row>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary rounded-pill mt-2 w-100"
            >
              {isSubmitting ? (
                <FormattedMessage
                  id="NewsletterForm.sending"
                  defaultMessage="Sending..."
                />
              ) : (
                <FormattedMessage
                  id="NewsletterForm.btnLabel"
                  defaultMessage="SIGN UP NOW"
                />
              )}
            </button>
            {status && status.message && (
              <p className="form-error-message form-error-message--red mt-2 text-center">
                {status.message}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
